@import url("https://fonts.googleapis.com/css?family=Roboto:300");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
}

.container {
  margin: auto;
  max-width: 1200px;
  padding: 0 10px;
}

button,
input[type=submit],
input[type=reset] {
  cursor: pointer;
}

h1 {
  margin-bottom: 12px;
}

h2 {
  margin-bottom: 10px;
}

.disclaimer {
  font-size: 14px;
  text-align: right;
  font-style: italic;
}

.table-holder {
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #bbb;
  padding: 8px;
}

td {
  font-size: 14px;
}

tr:nth-child(2n) {
  background-color: #f4f4f4;
}

td:nth-child(6n) {
  width: 80px;
}

.form-group {
  margin-bottom: 20px;
}

.chk-row {
  display: flex;
  align-items: center;
  margin-top: 4px;

  label,
  input {
    margin: 0
  }

  label {
    margin-right: 8px;
  }
}

label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.btn-row {
  display: flex;
}

input[type=number],
input[type=text],
input[type=date] {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #bbb;
  border-radius: 6px;
  outline: none;
}

input[type=number]:focus,
input[type=text]:focus,
input[type=date]:focus {
  border: 1px solid #0bf;
  box-shadow: 0 0 4px #999;
}

input[type=checkbox] {
  margin-right: 10px;
}

input[type=submit] {
  width: 100%;
  padding: 10px;
  background: #0bf;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 6px;
  margin-right: 10px;
}

input[type=reset] {
  width: 100%;
  padding: 10px;
  background: white;
  color: #0bf;
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 6px;
  border: 2px #0bf solid;
  margin-left: 10px;
  width: 100px;
}

input[type=submit]:hover {
  background: #0cf;
}

input[type=reset]:hover {
  background: #0cf;
  color: white
}

.print-row {
  display: flex;
  align-items: baseline;

  h2 {
    margin-right: 12px;
  }
}

.print {
  background: transparent;
  padding: 2px;
  border: 0;
  width: 30px;
  height: 30px;
  margin-left: auto;
}

.count {
  font-size: 16px;
  color: #666;
}

@media screen and (max-width: 900px) {
  h1 {
    margin-top: 10px;
    font-size: 20px;
  }

  th,
  td {
    padding: 5px;
    font-size: 12px;
  }

  .form-group {
    margin-bottom: 5px;
  }
}

@media print {
  @page { size: landscape; }
  td { font-size: 11px !important; }
  body { margin: 20px; }
}
